<template>
  <b-card body-text-variant="success" class="d-flex justify-content-center mb-1" style="max-width: 100rem; margin: auto;">
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>Plans</span>
        </template>
        <plan-list></plan-list>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>Billing</span>
        </template>
        <div v-if="!billings" class="d-flex justify-content-center mb-1">
          <b-spinner label="Loading..." />
        </div>
        <bill-list v-if="billings && plans" :data="billings" :plans="plans"></bill-list>
      </b-tab>
      
    </b-tabs>
  </b-card>
</template>

<script>
import { BCard, BCardText,BTab,BTabs, BSpinner } from 'bootstrap-vue'
import BillList from './BillList.vue'
import PlanList from './PlanList.vue'
import RepositoryFactory from '../../../api/RepositoryFactory'
const NPlansRepository = RepositoryFactory.get('nplans')
const BillingsRepository = RepositoryFactory.get('billings')

export default {
  components: {
    BCard,
    BCardText,
    BTab,
    BTabs,
    BSpinner,
    'bill-list':BillList,
    'plan-list':PlanList
  },
  data(){
    return {
      billings: null,
      plans: null
    }
  },
  created(){
    BillingsRepository.fetch().then(rs=>{
      this.billings = rs.data.data
      NPlansRepository.fetchAll().then(rs=>{
        
        this.plans = rs.data.data
        //console.log(this.plans)
        //console.log(this.plans)
        //console.log(this.plans)
      }).catch(e=>{

      })
    }).catch(e=>{

    })
    

  }
}
</script>

<style>

</style>
