<template>

  <b-card>
    <b-overlay
      :show="show"
      rounded="xl"
      :variant="variant"
      :opacity="opacity"
      :blur="blur"
    >
    <b-card-body>
      <div class="d-flex justify-content-between  flex-wrap">

        <!-- sorting  -->
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="2"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >
          <b-input-group
            size="sm"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- filter -->
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="data"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(status)="data">
        <b-badge :variant="status[0][data.item.status]">
          {{status[1][data.item.status]}}
        </b-badge>
      </template>
      <template #cell(amount)=data>
        {{Number(data.item.amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}}
      </template>
      <template #cell(created_at)=data>
        {{convertUTCDateToLocalDate(data.item.created_at)}}
      </template>
      <template #cell(receipt_url)=data>
        <a :href="data.item.receipt_url" target="_blank"><feather-icon icon="EyeIcon" /></a>
      </template>
      <template #cell(bandwith)=data>
        {{ getPlanBW(data.item.oid,data.item.d_type) }}
      </template>
      <template #cell(d_type)=data>
        <b-badge v-if="data.item.d_type == 0" variant="info">Buy BW in old</b-badge>
        <b-badge v-else-if="data.item.d_type == 1" variant="success">Buy BW in new</b-badge>
        <b-badge v-else-if="data.item.d_type == 2" variant="success">Buy WIP Port</b-badge>
        <b-badge v-else-if="data.item.d_type == 3" variant="success">Renew WIP Port</b-badge>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    </b-overlay>
  </b-card>
 
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BOverlay, BCard, BTooltip, BFormRadio
} from 'bootstrap-vue'

//import { PayPalIcon } from 'vue-brand-icons'
export default {
  props:{
    data:{
      type: Array,
      default: ()=>[],
    },
    plans: {
      type: Array,
      default: ()=>[],
    }
  },
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BCard,
    BTooltip,
    BFormRadio
  },
  data() {
    return {
      show:false,
      variant: 'light',
      opacity: 0.85,
      blur: '2px',
      perPage: 20,
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'created_at',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'oid', label: 'ID', sortable: true },
        { key: 'amount', label: 'Amount', sortable: true },
        {key: 'bandwith',label: 'bandwith (GB)'},
        { key: 'd_type', label: 'Type', sortable: true },
        { key: 'created_at', label: 'Time', sortable: true },
        // { key: 'plan', label: 'Description', sortable: true },
      ],
      /* eslint-disable global-require */
      /* eslint-disable global-require */
      status: [
        {
          0: 'light-success', 1: 'light-danger',
        },
        {
          0: 'Paid', 1: 'Pay Now',
        },
      ],

      tempPost: {
        bw: 100,
        ptype: 1
      }
    }
  },
  created(){
    this.show = false
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.data.length
  },
  methods: {
    showModal(){
      this.$bvModal.show('modal-buy-bw')
    },
    getPlanBW(oid, dtype){
      if(dtype == 1){
        return this.formatBytes(this.plans.find(x=>x.oid == oid) ? this.plans.find(x=>x.oid == oid).bandwidth : 0)
        //return this.plans.find(x=>x.oid == oid)
      }
        
      else
        return ""
    },
    formatDateTime(d){
      let date = new Date(d)
      let options = { year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false}
        return new Intl.DateTimeFormat('vi-VN', options).format(date)
        return date.toLocaleString()
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    convertUTCDateToLocalDate(d) {
      try{
        let date = new Date(d)
        return date.toLocaleString()
      }catch(e){
        console.log(d)

        return ""
      }
      
      var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

      var offset = date.getTimezoneOffset() / 60;
      var hours = date.getHours();

      newDate.setHours(hours - offset);

      return this.formatDateTime(newDate);   
    },
    buyBW(){

    },
    formatBytes(bytes, t=true) {
      var marker = 1000; // Change to 1000 if required
      var decimal = 2; // Change as required
      var kiloBytes = marker; // One Kilobyte is 1024 bytes
      var megaBytes = marker * marker; // One MB is 1024 KB
      var gigaBytes = marker * marker * marker; // One GB is 1024 MB
      var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB
      // return bytes if less than a KB
      if(t){
        if(bytes < kiloBytes) return bytes + " Bytes";
        // return KB if less than a MB
        else if(bytes < megaBytes) return(bytes / kiloBytes).toFixed(decimal) + " KB";
        // return MB if less than a GB  ``
        else if(bytes < gigaBytes) return(bytes / megaBytes).toFixed(decimal) + " MB";
        // return GB if less than a TB
        else if(bytes < teraBytes) return (bytes / gigaBytes).toFixed(decimal) + " GB";
        
        else return (bytes / teraBytes).toFixed(decimal) + " TB";
      }else{
        if(bytes < kiloBytes) return bytes + " Bytes";
        // return KB if less than a MB
        else if(bytes < megaBytes) return(bytes / kiloBytes).toFixed(decimal) + " KB";
        // return MB if less than a GB  ``
        else return(bytes / megaBytes).toFixed(decimal) + " MB";
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-select.scss';
.dark-layout {
  div ::v-deep .card .card-body {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>